<template>
  <ml-container>
    <ml-grid>
      <div class="tw-col-span-5">
        <div>

          <form ref="form" novalidate="false" @submit.prevent="onSubmit" @change="validateForm()" method="POST">

            <div class="tw-flex tw-items-center tw-justify-between">
              <h1 class="tw-block tw-text-black tw-text-xl tw-leading-relaxed tw-font-semibold tw-mb-4">
                {{ titleText }}

                {{ item.quote ? $t('vendor.offer.quote_request_creation_label') : null }}
              </h1>
              <router-link :to="{ name: 'vendor.offers' }" class="tw-text-red tw-text-sm tw-flex tw-items-center tw-mb-4">
                <svg class="tw-mr-3" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 1L1 5L5 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                {{$t('general.back')}}
              </router-link>
            </div>

            <div>
              <div class="tw-overflow-hidden tw-mt-8 tw-flex tw-pr-4 tw-border tw-border-solid tw-border-gray-n1 tw-rounded-xl sm:tw-text-sm" v-if="quote">
                <div class="tw-w-2 tw-box-border tw-bg-red-100"/>

                <div class="tw-px-3 tw-py-3">
                  <ml-label>Requested quote</ml-label>
                  <p class="tw-pt-2 tw-pb-3">{{quote.quote_description}}</p>
                  <div
                      v-for="(date,index) in this.quote.dates" :key=index
                      class="tw-inline-flex tw-bg-gray-n0 tw-mr-2 tw-font-xs tw-rounded-xl tw-mb-2 tw-min-h-0 tw-p-4 fix-bg-black"
                  >
                    <div class="tw-m-0 tw-mr-2">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.6667 2.66669H3.33333C2.59695 2.66669 2 3.26364 2 4.00002V13.3334C2 14.0697 2.59695 14.6667 3.33333 14.6667H12.6667C13.403 14.6667 14 14.0697 14 13.3334V4.00002C14 3.26364 13.403 2.66669 12.6667 2.66669Z" stroke="#2D2D2D" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10.6667 1.33331V3.99998" stroke="#2D2D2D" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M5.33325 1.33331V3.99998" stroke="#2D2D2D" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2 6.66669H14" stroke="#2D2D2D" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                    <div class="tw-text-xs tw-self-center">
                      {{date.datef}}
                    </div>

                  </div>
                </div>


              </div>

              <div class="tw-mt-8">
                <ml-label required for="categories">{{$t('vendor.offer.categories')}}</ml-label>
                <div class="tw-mt-2">
                  <v-select
                      v-model="item.categories"
                      :items="categories"
                      :rules="[required]"
                      item-text="name"
                      item-value="id"
                      
                      chips
                      dense
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip color="white" v-if="index === 0" >
                            <span v-for="(parent,index) in item.parents" :key="index">
                                  {{parent.name}} |
                            </span>
                            <span>{{ item.name }}</span>
                          </v-chip>
                    </template>

                      <template v-slot:item="{item,attrs, on}">
                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-row no-gutters align="center">
                                <div class="col">
                                  <span v-for="(parent,index) in item.parents" :key="index">
                                  {{parent.name}} |
                                  </span>
                                  
                                  <span :style="active ? 'font-weight:bold':''">{{ item.name }}</span> 
                                </div>
                                <div class="col" align="right">
                                   <span style="color:gray">{{item.type.name}}</span>
                                </div>                                
                              </v-row>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>

                  </v-select>
                  <ml-error-msg :msg="errors['categories']" />
                </div>
              </div>
              <div class="tw-mt-8">
                <ml-label required for="name">{{$t('vendor.offer.name')}}</ml-label>
                <div class="tw-mt-2">
                  <ml-input-text v-model="item.name" id="name" name="name" required/>
                  <ml-error-msg :msg="errors['name']" />
                </div>
              </div>
              <div class="tw-mt-6">
                <div class="tw-mt-2">
                    <div class="tw-text-sm tw-text-black tw-flex tw-items-center tw-space-x-3">
                      <span>{{ item.is_published ? $t('vendor.offer.published'):$t('vendor.offer.publish') }}</span>
                      <button :value="item.is_published" @click="item.is_published = !item.is_published" type="button"
                              :class="[item.is_published ? 'tw-bg-green-100' : 'tw-bg-red-100', 'tw-border-solid tw-relative tw-inline-flex tw-flex-shrink-0 tw-h-5 tw-w-10 tw-border-2 tw-border-transparent tw-rounded-full tw-cursor-pointer tw-transition-colors tw-ease-in-out tw-duration-200 focus:tw-outline-none ']" role="switch" aria-checked="false">
                        
                        <span aria-hidden="true" :class="[item.is_published ? 'tw-translate-x-5 tw-bg-green' : 'tw-translate-x-0 tw-bg-red', 'tw-pointer-events-none tw-inline-block tw-h-4 tw-w-4 tw-rounded-full tw-shadow tw-transform tw-ring-0 tw-transition tw-ease-in-out tw-duration-200']"></span>
                      </button>
                    </div>

                </div>
              </div>

              <div class="tw-mt-6">
                <ml-label required for="description">{{$t('vendor.offer.description')}}</ml-label>
                <div class="tw-mt-2">
                  <!--
                  <ml-textarea v-model="item.description" id="description" name="description" required/>
                  -->
                  <editor
                      id="description" name="description"
                      v-model="item.description"
                      :init="{
                            
                            menubar: false,
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar:
                              'undo redo | bold italic backcolor | \
                              alignleft aligncenter alignright alignjustify | \
                              bullist numlist outdent indent | removeformat | help'
                      }"
                  />                  
                  <ml-error-msg :msg="errors['description']" />
                </div>
              </div>

              <ml-grid class="tw-mt-6">
                <div class="tw-col-span-4">
                  <ml-label required for="price">{{$t('vendor.offer.price')}}</ml-label>
                  <div class="tw-mt-2">
                    <ml-input-text type="number" min="0" step="1" placeholder="USD" v-model="item.price" id="price" name="price" required/>
                    <ml-error-msg :msg="errors['price']" />
                  </div>
                </div>
                <div class="tw-col-span-4">
                  <ml-label for="package_discount">{{$t('vendor.offer.discount')}}</ml-label>
                  <div class="tw-mt-2">
                    <div class="tw-relative">
                      <div class="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none">
                      <span class="tw-text-gray-m3 sm:tw-text-sm">
                        %
                      </span>
                      </div>
                      <ml-input-text class="tw-pl-7" type="number" min="0" max="100" v-model="item.package_discount" id="package_discount" name="package_discount" required/>
                    </div>
                    <ml-error-msg :msg="errors['package_discount']" />
                  </div>
                </div>
              </ml-grid>

              <div class="tw-mt-6" v-if="addons.length > 0">
               
                  <ml-label for="products">{{$t('vendor.addons')}} (optional for purchasing)</ml-label>
                  <div class="tw-mt-2">
                    <v-select
                        v-model="item.addons"
                        :items="addons"
                        item-text="name"
                        item-value="id"
                        multiple
                        
                        chips
                        dense
                    >
                    <template v-slot:selection="{ item }">
                      <v-chip color="white">
                            <span>{{ item.name }} | {{item.price | currency}}</span>
                          </v-chip>
                    </template>

                      <template v-slot:item="{item, attrs, on}">
                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                          <v-list-item-action>
                            <v-checkbox :ripple="false" :input-value="active"></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-row no-gutters align="center">
                                <div class="col">
                                {{ item.name }} 
                                </div>
                                <div class="col" align="right">
                                   <span class="tw-text-red">{{item.price | currency}}</span>
                                </div>                                
                              </v-row>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
        
                      <template v-slot:prepend-item>
                        <v-list-item
                          ripple
                          @mousedown.prevent
                          @click="toggleAddons"
                        >
                          <v-list-item-action>
                            <v-icon :color="item.addons.length > 0 ? 'grey darken-4' : ''">
                              {{ iconAddons }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Select All
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>


                      <template v-slot:append-item>
                            <v-divider class="mb-2"></v-divider>
                          <v-list-item disabled>
                            <v-list-item-avatar color="grey lighten-3">
                              <v-icon>
                                mdi-basket-fill
                              </v-icon>
                            </v-list-item-avatar>

                        <v-list-item-content v-if="likesAllAddons">
                          <v-list-item-title>
                            All Addons are selected to this Service or Goods
                          </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-content v-else-if="likesSomeAddons">
                          <v-list-item-title>
                            Addons Count
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ item.addons.length }}
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-content v-else>
                          <v-list-item-title>
                            Please, select one of Addons?
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Go ahead, make a selection above!
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      </template>  

                    </v-select>
                    <ml-error-msg :msg="errors['addons']" />
                  </div>
                

              </div>

              <div class="tw-mt-6" v-if="fees.length > 0 && 1==2">
               
                  <ml-label for="products">{{$t('vendor.fees')}}</ml-label>
                  <div class="tw-mt-2">
                    <v-select
                        v-model="item.fees"
                        :items="fees"
                        item-text="name"
                        item-value="id"
                        multiple
                        
                        chips
                        dense
                    >
                    <template v-slot:selection="{ item }">
                      <v-chip color="white">
                            <span>{{ item.name }} | {{item.price | currency}}</span>
                          </v-chip>
                    </template>

                      <template v-slot:item="{item, attrs, on}">
                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                          <v-list-item-action>
                            <v-checkbox :ripple="false" :input-value="active"></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-row no-gutters align="center">
                                <div class="col">
                                {{ item.name }} 
                                </div>
                                <div class="col" align="right">
                                   <span class="tw-text-red">{{item.price | currency}}</span>
                                </div>                                
                              </v-row>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
        
                      <template v-slot:prepend-item>
                        <v-list-item
                          ripple
                          @mousedown.prevent
                          @click="toggleFees"
                        >
                          <v-list-item-action>
                            <v-icon :color="item.fees.length > 0 ? 'grey darken-4' : ''">
                              {{ iconFees }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Select All
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>


                      <template v-slot:append-item>
                            <v-divider class="mb-2"></v-divider>
                          <v-list-item disabled>
                            <v-list-item-avatar color="grey lighten-3">
                              <v-icon>
                                mdi-basket-fill
                              </v-icon>
                            </v-list-item-avatar>

                        <v-list-item-content v-if="likesAllFees">
                          <v-list-item-title>
                            All Fees are selected to this Package
                          </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-content v-else-if="likesSomeFees">
                          <v-list-item-title>
                            Fees Count
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ item.fees.length }}
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-content v-else>
                          <v-list-item-title>
                            Please, select one of Fees?
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Go ahead, make a selection above!
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      </template>  

                    </v-select>
                    <ml-error-msg :msg="errors['fees']" />
                  </div>
                

              </div>


              <div class="tw-mt-6" v-if="fees.length > 0">
               
                  <ml-label for="products">{{$t('vendor.fees')}} (included in price)</ml-label>
                  <div class="tw-mt-2">
                    <v-select
                        v-model="item.products"
                        :items="fees"
                        item-text="name"
                        item-value="id"
                        multiple
                        
                        chips
                        dense
                    >
                    <template v-slot:selection="{ item }">
                      <v-chip color="white">
                            <span>{{ item.name }} | {{item.price | currency}}</span>
                          </v-chip>
                    </template>

                      <template v-slot:item="{item, attrs, on}">
                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                          <v-list-item-action>
                            <v-checkbox :ripple="false" :input-value="active"></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-row no-gutters align="center">
                                <div class="col">
                                {{ item.name }} 
                                </div>
                                <div class="col" align="right">
                                   <span class="tw-text-red">{{item.price | currency}}</span>
                                </div>                                
                              </v-row>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
        
                      <template v-slot:prepend-item>
                        <v-list-item
                          ripple
                          @mousedown.prevent
                          @click="toggleProducts"
                        >
                          <v-list-item-action>
                            <v-icon :color="item.products.length > 0 ? 'grey darken-4' : ''">
                              {{ iconProducts }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Select All
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>


                      <template v-slot:append-item>
                            <v-divider class="mb-2"></v-divider>
                          <v-list-item disabled>
                            <v-list-item-avatar color="grey lighten-3">
                              <v-icon>
                                mdi-basket-fill
                              </v-icon>
                            </v-list-item-avatar>

                        <v-list-item-content v-if="likesAllProducts">
                          <v-list-item-title>
                            All Fees are selected to this Package
                          </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-content v-else-if="likesSomeProducts">
                          <v-list-item-title>
                            Fees Count
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ item.products.length }}
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-content v-else>
                          <v-list-item-title>
                            Please, select one of Fees?
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Go ahead, make a selection above!
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      </template>  

                    </v-select>
                    <ml-error-msg :msg="errors['fees']" />
                  </div>
                

              </div>


              <div class="tw-mt-6" v-if="item.id">
                <ml-label>Photos</ml-label>
                <gallery v-if="item.images && item.images.length > 0"
                         class="tw-mt-3"
                         :images="item.images"
                         :gallery-setup="{}"
                         :default-image-class="'tw-h-28'"
                         :thumb-only="true"
                         :limit="item.images.length"
                         :thumb-wrapper-class="'tw-grid tw-grid-cols-4 tw-gap-x-3 tw-gap-y-3'"
                         :show-border="false"
                >
                  <template v-slot:image-action="{image}">
                    <a href="#" @click.prevent="destroyImage(image.id)"
                       class="tw-right-2 tw-top-2 tw-h-6 tw-w-6 tw-bg-white tw-rounded-full tw-absolute tw-text-black tw-flex tw-items-center tw-justify-center">
                      <x-icon class="tw-h-3 tw-w-3" />
                    </a>
                  </template>
                </gallery>
              </div>
              <div class="tw-mt-6">
                <div v-if="item.id">
                      <!-- Image -->

                      <v-file-input
                          @change="onFileSelected"
                          accept="image/png, image/jpeg, image/bmp"
                          :placeholder="$t('vendor.pick_image')"
                          prepend-icon="mdi-camera"
                          :label="$t('vendor.image_file_size')"
                          v-model="file"
                      ></v-file-input>
               
                      <v-progress-linear
                          v-show="imageUpload"
                          indeterminate
                          color="red"
                          class="mb-0"
                      ></v-progress-linear>

                      <v-alert
                        v-if="imageUploadError"
                        color="orange"
                        outlined
                        shaped
                        type="info"
                        >
                        <p>{{imageUploadError.message}}</p>

                        <p v-for="(e,index) in imageUploadError.errors.image" :key="index">
                          {{e}}
                        </p>

                      </v-alert>                    
                </div>
           
              </div>
            </div>
              <div class="tw-mt-6">
               <strong> <span>Total: {{item.total_price_with_discount | currency}}</span></strong> |
               <span class="tw-text-xs tw-italic"> (calculated: {{totalServiceGoods | currency}})</span>
              </div>

            <div class="tw-mt-6">
              <router-link :to="{ name: 'vendor.offers' }"
                      class="tw-mt-6 tw-mr-6 tw-px-8 tw-py-3 tw-border tw-border-solid tw-border-black tw-text-base tw-font-medium tw-rounded-xl tw-text-black tw-bg-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
                {{$t('general.cancel')}}
              </router-link>
              <button type="submit"
                      :disabled="loading"
                      class="tw-mt-6 tw-px-8 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-black focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-black">
                {{ buttonText }}
              </button>
              <button type="submit" :disabled="loading"
              @click="item.is_published=true"
                  class="tw-mt-6 tw-ml-3 tw-px-8 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-red focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-black">
                {{ buttonText }} & {{$t('vendor.publish')}}
              </button>
            </div>
          </form>
        </div>
      </div>

    </ml-grid>
  </ml-container>
</template>

<script>

import MlContainer from "@/components/layout/MlContainer";
import MlGrid from "@/components/layout/MlGrid";
import MlLabel from "@/components/layout/form/MlLabel";
import MlInputText from "@/components/layout/form/MlInputText";
//import MlTextarea from "@/components/layout/form/MlTextarea";
import MlErrorMsg from "@/components/layout/form/MlErrorMsg";
import Gallery from "@/components/Gallery";
import { XIcon } from 'vue-feather-icons';
import i18n from '@/i18n'

import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
import Editor from '@tinymce/tinymce-vue';

export default {
  components: {MlErrorMsg, MlGrid, MlContainer, MlLabel, MlInputText, Gallery, XIcon, Editor},
  middleware: ['auth', 'vendor', 'is-vendor-active'],
  metaInfo() {
    return {
      title: i18n.t('productmng'),
    }
  },
  data() {
    return {
      taxes: [],
      taxTypes: [],
      categories: [],
      cat: [],
      addons: [],
      fees: [],
      quote: null,
      item: {
        name: null,
        is_published: false,
        quote: null,
        package_discount: 0,
        tax_inclusive: false,
        addons: [],
        fees: [],
        products: [],
      },
      selectedFile: null,
      imageUploadError: null,
      imageUpload:false,      
      loading: false,
      file: null,
      rules: {
        required: value => !!value || 'Required.'
      },
      valid: true,
      lazy: true,
      errored: false,
      errors: [],
      eperrors: null,
      eperrorMsg: ""

    }
  },
  mounted: function () {
    this.getTaxTypes();
    this.getCategories();
    this.getAddons();
    this.getFees();

    if (this.$route.params.id) {
     this.getItem();
    }
    //this.getTaxes();
    if (this.$route.query.quote) {
      this.getQuote();
    }
  },
  watch:{
    eperrors: function(){
      //console.log("Object.values(object1)",Object.values(this.eperrors));
      Object.values(this.eperrors).forEach(element => {
        this.eperrorMsg = this.eperrorMsg + element[0];
      });
      //console.log("eperrorMsg",this.eperrorMsg);
      this.$toast.error(this.eperrorMsg, "Error", {timeout: 5000});
      this.eperrorMsg = "";
    },
  },
  computed: {
     totalServiceGoods(){
        if(this.item.price){

              let summed = 0.00;

              // Selected fees
              var selectedFees = this.fees.filter(item => this.item.products.includes(item.id)).map(a => a.price);

              for (let key in selectedFees) {
                  summed += Number(selectedFees[key]);
              }
              summed += Number(this.item.price);

              if(this.item.package_discount>0 && this.item.package_discount<=100){
                  summed = summed - ((summed / 100)*this.item.package_discount).toFixed(2);
              }               
              
              return summed;

        }else{
          return 0;
        }


      },    
    likesAllAddons () {
      return this.item.addons.length === this.addons.length
    },
    likesSomeAddons () {
      return this.item.addons.length > 0 && !this.likesAllAddons
    },
    iconAddons () {
      if (this.likesAllAddons) return 'mdi-close-box'
      if (this.likesSomeAddons) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    likesAllFees () {
      return this.item.fees.length === this.fees.length
    },
    likesSomeFees () {
      return this.item.fees.length > 0 && !this.likesAllFees
    },
    iconFees () {
      if (this.likesAllFees) return 'mdi-close-box'
      if (this.likesSomeFees) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    likesAllProducts () {
      return this.item.products.length === this.fees.length
    },
    likesSomeProducts () {
      return this.item.products.length > 0 && !this.likesAllProducts
    },
    iconProducts () {
      if (this.likesAllProducts) return 'mdi-close-box'
      if (this.likesSomeProducts) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },      
    buttonText() {
      return this.item.id ? 'Save' : 'Create'
    },
    titleText() {
      return this.$route.params.id ? i18n.t('vendor.offer.edit_product') : i18n.t('vendor.offer.create_product')
    },
    dates() {
      if (this.quote.dates) {
        return this.quote.dates.map(day => day.date);
      } else {
        return null;
      }
    },
    attributes() {
      return this.dates.map(date => ({
        highlight: true,
        dates: date,
      }));
    },
  },
  methods: {
    updateTitle(name){
        console.log("name:",name)
        this.item.name = name.toString();


    },
    toggleAddons () {
        this.$nextTick(() => {
          if (this.likesAllAddons) {
            this.item.addons = []
          } else {
            this.item.addons = this.addons.slice()
          }
          this.item.addons = this.item.addons.map(a => a.id);
        })
    },
    toggleFees () {
        this.$nextTick(() => {
          if (this.likesAllFees) {
            this.item.fees = []
          } else {
            this.item.fees = this.fees.slice()
          }
          this.item.fees = this.item.fees.map(a => a.id);
        })
    }, 
    toggleProducts () {
        this.$nextTick(() => {
          if (this.likesAllProducts) {
            this.item.products = []
          } else {
            this.item.products = this.fees.slice()
          }
          this.item.products = this.item.products.map(a => a.id);
        })
    },          
    onDayClick(day) {
      console.log(day);
      return true;
      /*
      const idx = this.quote.dates.findIndex(d => d.id === day.id);
      if (idx >= 0) {
          this.quote.dates.splice(idx, 1);
      } else {
          this.quote.dates.push({
          id: day.id,
          date: day.date,
          });
      }
      */
    },
    required(value) {
      if (value instanceof Array && value.length === 0) {
        return 'Required.';
      }
      return !!value || 'Required.';
    },
    validateForm() {

      console.log("validate form...");
      this.errors = [];
      let label = '';

        if (!this.item.name) {
          label = "Name required.";
          this.errors.push(label);
          this.errors["name"] = label;
        }

        if (!this.item.description) {
          label = "Description required.";
          this.errors.push(label);
          this.errors["description"] = label;
        }

        if (!this.item.price) {
          label = "Price required.";
          this.errors.push(label);
          this.errors["price"] = label;
        }

        if ((!this.between(this.item.package_discount,0,75) || !this.validatePercentage(this.item.package_discount))) {
          label = "Discount min 0 max 75.";
          this.errors.push(label);
          this.errors["package_discount"] = label;
        }
        
        if (this.item.price) {
          if (this.item.price-(this.item.price*(this.item.package_discount/100))<10) {
            label = "The minimum amount is $10 (included discount)";
            this.errors.push(label);
            this.errors["price"] = label;
          }
        }
        
        /*
        if (!this.item.tax_type) {
          label = "Tax type required.";
          this.errors.push(label);
          this.errors["tax_type"] = label;
        }
        */

        if (this.item.categories instanceof Array && this.item.categories.length === 0 || !this.item.categories) {
          label = "Category required.";
          this.errors.push(label);
          this.errors["categories"] = label;
        }        

      if (!this.errors.length) {
        this.valid = true;
        return true;
      }

      this.valid = false;
      return false;

    },
    validatePercentage(v){
      var re = /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/;
      return re.test(v);
    },
    between(x, min, max) {
        return ((x-min)*(x-max) <= 0);
    },
    onSubmit(){
      if(this.validateForm()) this.dataEdit();
    },    
    async dataEdit() {

      if (this.item.id) {
        this.updateItem();
      } else {
        this.addItem();
      }
    },
    mapData() {
      //this.item.tax = this.item.tax.id;
      //if (this.item.tax_type) this.item.tax_type = this.item.tax_type.id;
      //this.cat = this.item.categories.map(a => a.id);
      //this.item.categories = this.item.categories.map(a => a.id);
      //this.item.fees = this.item.fees.map(a => a.id);
      
      this.item.addons = this.item.addons.map(a => a.id);
      this.item.categories = this.item.categories[0].id;
      this.item.products = this.item.products.map(a => a.id);
      
      //this.item.type_id = this.item.type.id;
    },
    async addItem() {

      //await this.$refs.form.validate();
      if(!this.validateForm()){
        return;
      }
      if (!this.valid) return;
      this.loading = true;
      this.item.type_id = 2;
      let uri = '/api/vendor/products';
      await this.$http.post(uri, this.item)
          .then((response) => {
            this.item = response.data.data
            this.mapData()
            this.$toast.success(response.data.message, "Success")

          }).catch(error => {
            //this.$toast.error(error.data.message, "Error");
            //console.log("error",error.data.errors);
            this.eperrors = error.data.errors;
            this.loading = false;
            this.errored = true;
          });
      this.loading = false;
    },
    async updateItem() {

      //await this.$refs.form.validate();
      if(!this.validateForm()){
        return;
      }
      this.loading = true;
      if (!this.valid) return;
      let uri = '/api/vendor/products/' + this.item.id;
      await this.$http.patch(uri, this.item)
          .then((response) => {
            this.$toast.success(response.data.message, "Success");
            this.$router.push({name: 'vendor.offers', query: { page: 'Product' }})
            //this.$router.go(-1)
          }).catch(error => {
            //this.$toast.error(error.data.message, "Error");
            //console.log("error",error.data.errors);
            this.eperrors = error.data.errors;
            this.loading = false;
            this.errored = true;
          });
      this.loading = false;          
    },
    async getItem() {
      this.loading = true;
      let uri = '/api/vendor/products/' + this.$route.params.id + '/edit';
      this.$http.get(uri)
          .then((response) => {
            this.item = response.data.data
            this.quote = response.data.data.quote
          }).catch(error => {
        console.log('Error loading data: ' + error)
        this.errored = true
      }).finally(() =>
              this.mapData(),
          this.loading = false
      );

    },
    getTaxes() {
      let uri = '/api/vendor/taxes';
      this.$http.get(uri).then((response) => {
        this.taxes = response.data.data
      });
    },
    getTaxTypes() {
      let uri = '/api/vendor/tax/types';
      this.$http.get(uri).then((response) => {
        this.taxTypes = response.data.data
      });
    },
    getFees() {
      let uri = '/api/vendor/fees?filter[is_published]=1';
      this.$http.get(uri).then((response) => {
        this.fees = response.data.data
      });
    },    
    getCategories() {
      let uri = '/api/vendor/categories?filter[tax_code_null]=false&filter[type_name_in]=Service,Goods';
      this.$http.get(uri).then((response) => {
        this.categories = response.data.data
      });
    },
    getAddons() {
      let uri = '/api/vendor/addons?filter[is_published]=1';
      this.$http.get(uri).then((response) => {
        this.addons = response.data.data
      });
    },
    getQuote() {
      let uri = '/api/vendor/quotes/' + this.$route.query.quote;
      this.$http.get(uri).then((response) => {
        this.quote = response.data.data;
        this.item.quote = this.quote.id;
      });
    },
    onFileSelected(event) {
      console.log(event);
      if (event == null) return;
      //this.selectedFile = event.target.files[0];
      this.selectedFile = event;
      this.onUpload();
    },
    onUpload() {
      this.imageUpload = true;
      this.imageUploadError = null;      
      const formData = new FormData();
      formData.append('image', this.selectedFile, this.selectedFile.name);
      let uri = '/api/vendor/products/images/' + this.item.id;
      this.$http.post(uri, formData, {
        onUploadProgress: uploadEvent => {
          console.log('Upload progress: ' + Math.round(uploadEvent.loaded / uploadEvent.total * 100));
        }
      })
          .then((response) => {
            this.item.images = response.data.images;
            //this.item.images = this.item.images.concat(response.data.images);
            this.$toast.success(response.data.message, "Success")
            //this.$refs.inputFile.reset();
            this.file = null;
            this.imageUpload = false;
          }).catch((error) => {
            this.imageUpload = false;
            this.imageUploadError = error.data;
            //console.log('Error: ', error.data);
            this.eperrors = error.data.errors;
          });
    },
    destroyImage(vId) {
      this.$toast.question('Are you sure about that?', "Confirm", {
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: 'once',
        id: 'address',
        zindex: 999,
        title: 'Hey',
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', (instance, toast) => {

            this.onImageDelete(vId);

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }, true],
          ['<button>NO</button>', function (instance, toast) {

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }],
        ]
      });
    },
    onImageDelete(id) {
      if (!this.item.id) return;
      let uri = '/api/vendor/products/' + this.item.id + '/images/' + id;
      this.$http.delete(uri)
          .then((response) => {
            //this.item.images.push(response.data.images),
            this.item.images = response.data.images;
            //this.item.images = response.data.images;
            this.$toast.success(response.data.message, "Success", {timeout: 1000});
          });
    },
  }
}
</script>
